import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
    Grid,
} from '@mui/material';

import { Loader } from "UI";

import { selectLoading, sendGetApplicationList, selectApplicationList } from "features/pages/pagesSlice";
import { ApplicationItem, ApplicationInfo } from "features/pages";

const ApplicationListComponent = () => {
    const dispatch = useDispatch();
    const { applicationList: loading } = useSelector(selectLoading);

    const list = useSelector(selectApplicationList);
    const listFiltered = list.filter(item => item.numberOfKeys);

    console.log(list);

    useEffect(() => {
        dispatch(sendGetApplicationList());
    }, [dispatch]);

    return <>
        <ApplicationInfo />
        <Box sx={{ height: "calc(100vh - 142px)", width: '100%' }}>
            <Loader loading={loading} variant="replace">
                <Grid container sx={{
                    display: "grid",
                    gap: "20px",
                    paddingTop: "10px",
                    gridTemplateColumns: "repeat(auto-fill, minmax(370px, 1fr))",
                }}>
                    {listFiltered.map((elem) => (
                        <ApplicationItem model={elem} key={elem.serviceNumber} />
                    ))}
                </Grid>
            </Loader>
        </Box>
    </>;
}

export const ApplicationList = memo(ApplicationListComponent);
