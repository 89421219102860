import React, { memo, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from '@mui/material/Button';
import {
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  useMediaQuery,
  TableCell,
  TableRow,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { Loader } from "UI/Loader";

import {
  sendGetServiceInfo,
  selectServiceApplicationId,
  selectCurrentServiceList,
  selectLoading,
  selectServiceInfoByid,
  resetCurrentService,
} from "features/pages/pagesSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ServiceInfoComponent = () => {
  const [tab, setTab] = React.useState(0);

  const { applicationInfo: loading } = useSelector(selectLoading);

  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const currentServiceId = useSelector(selectServiceApplicationId);
  const applicationTabs = useSelector(selectCurrentServiceList);
  const applicationCurrentTab = useMemo(() => applicationTabs[tab] || {}, [applicationTabs, tab]);
  const applicationInfo = useSelector((state) => selectServiceInfoByid(state, applicationCurrentTab.id));

  const handleClose = () => {
    dispatch(resetCurrentService());
    setTab(0);
  };

  const handleChangeTab = (_, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (applicationCurrentTab.id) {
      dispatch(sendGetServiceInfo({ id: applicationCurrentTab.id }));
    }
  }, [dispatch, applicationCurrentTab]);


  const rows = [
    {
      name: "Статус",
      value: applicationInfo.statusText,
    },
    {
      name: "Ключ",
      value: applicationInfo.licenseKey,
    },
    {
      name: "Срок действия ключа (в днях)",
      value: applicationInfo.validity,
    },
    {
      name: "Лимит по частоте запросов (интервал между запросами в секундах)",
      value: applicationInfo.rateLimit,
    },
    {
      name: "Лимит по количеству запросов за минуту",
      value: applicationInfo.minuteLimit,
    },
    {
      name: "Лимит по количеству запросов за сутки",
      value: applicationInfo.dayLimit,
    },
    {
      name: "Лимит по общему количеству запросов",
      value: applicationInfo.totalLimit,
    },
    {
      name: "Общее количество удачных запросов",
      value: applicationInfo.totalRequest,
    },
    {
      name: "Количество удачных запросов за сутки",
      value: applicationInfo.dayRequest,
    },
    {
      name: "Время последнего запроса",
      value: applicationInfo.lastRequest > 0 ? new Date(applicationInfo.lastRequest).toLocaleString() : "",
    },
  ];

  const rowsFiltered = rows.filter(row => row.value);

  return (applicationInfo &&
    <Dialog
      fullScreen={fullScreen}
      open={!!currentServiceId}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Loader loading={loading} variant="replace" >
        <DialogTitle id="responsive-dialog-title">
          {applicationInfo.name}
        </DialogTitle>

        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', minWidth: "400px" }}>
            {applicationTabs.length > 1 && <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
              {applicationTabs.map((tab, index) => <Tab key={tab.id} label={<>{`Ключ ${index + 1}`}<Typography sx={{ fontSize: 10 }}>{tab.licenseKey}</Typography></>} />)}
            </Tabs>}
          </Box>
          <TabPanel value={tab} index={tab}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                {rowsFiltered.map((row) => (
                  <TableRow
                    key={row.name}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>

                    <TableCell align="right">
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabPanel>
        </DialogContent>
      </Loader>

      <DialogActions>
        <Button onClick={handleClose}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const ServiceInfo = memo(ServiceInfoComponent);