import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'features/auth/authSlice';
import alertsReducer from 'features/alerts/alertsSlice';
import pagesReducer from 'features/pages/pagesSlice';
import patientsReducer from 'features/patients/patientsSlice';
import friendsReducer from 'features/friends/friendsSlice';
import referralReducer from 'features/referral/referralSlice';
import mainReducer from 'features/main/mainSlice';
import walletReducer from 'features/wallet/walletSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    alerts: alertsReducer,
    pages: pagesReducer,
    patients: patientsReducer,
    friends: friendsReducer,
    referral: referralReducer,
    main: mainReducer,
    wallet: walletReducer,
  },
});
