import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import {
  Avatar,
  CardContent,
  Card,
  CardHeader,
  CardActions,
  Grid,
  IconButton,
  Zoom,
} from "@mui/material";

import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import KeyIcon from "@mui/icons-material/Key";
import LaunchIcon from "@mui/icons-material/Launch";
import WorkIcon from "@mui/icons-material/Work";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  setCurrentApplicationId,
  setServiceApplicationId,
  resetCurrentService,
} from "features/pages/pagesSlice";

import { ServiceList } from "features/pages";

const ApplicationItemComponent = (props) => {
  const [fullScreen, setFullScreen] = useState(false);

  const dispatch = useDispatch();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickTitle = () => {
    dispatch(setCurrentApplicationId(props.model.serviceNumber));
  }

  const handleClickService = () => {
    // event.preventDefault();
    // event.stopPropagation();
    console.log("SERVICE");
    if (fullScreen) {
      dispatch(resetCurrentService());
      setFullScreen(false);
    } else {
      dispatch(setServiceApplicationId(props.model.id));
      setFullScreen(true);

    }
    // dispatch(setCurrentApplicationId(props.model.serviceNumber));
  }

  const handleLaunchApp = () => {
    // event.preventDefault();
    // event.stopPropagation();
    window.open(props.model.urlLink);
  }

  const fullScreenSX = {
    position: "absolute",
    zIndex: 100,
    left: 0,
    top: "24px",
    width: "calc(100vw - 20px)",
    height: "calc(100vh - 70px)",
    // overflowY: "visible",
  };

  const additionalSX = fullScreen ? fullScreenSX : {};

  return (<Zoom in={true} >
    <Grid sx={{
      transition: "all, 0.5",
      width: mediumScreen ? "96%" : "unset",
      ...additionalSX
    }}>
      <Card
        sx={{
          width: mediumScreen ? "100%" : "350px",
          margin: "10px",
          ...additionalSX
        }}
      >

        <CardHeader
          titleTypographyProps={{ variant: "subtitle2" }}
          subheaderTypographyProps={{ variant: "subtitle1" }}
          sx={{ pb: "0px", alignItems: "flex-start" }}
          action={fullScreen ?
            <IconButton aria-label="back" onClick={handleClickService}>
              <ArrowBackIcon />
            </IconButton> : null
          }
          avatar={
            <Avatar
              src={props.model.avatar}
              sx={!smallScreen ? { width: 62, height: 62 } : {}}
            >
              <LaptopMacIcon fontSize="large" sx={{ fontSize: smallScreen ? "32px" : "42px" }} />
            </Avatar>
          }
          title={props.model.name}
          subheader={props.model.statusText}
        />
        <CardActions disableSpacing sx={{ borderBottom: 1, borderColor: 'divider', }}>
          <Grid container>
            <Grid
              item
              xs={8}
            >
              <IconButton color="info" aria-label="keys" title="Ключи" onClick={handleClickTitle}>
                <KeyIcon />
              </IconButton>
              <IconButton color="info" aria-label="service" title="Услуги" onClick={handleClickService}>
                <WorkIcon />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton title="Перейти в приложение" aria-label="Launch" onClick={handleLaunchApp}>
                <LaunchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </CardActions>
        <CardContent sx={{ m: 0, p: "0 !important", backgroundColor: "#3983e2" }}>
          <ServiceList id={props.model.id} />
        </CardContent>
      </Card>
    </Grid>
  </Zoom>
  );
}


export const ApplicationItem = memo(ApplicationItemComponent);