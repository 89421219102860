import React, { memo } from "react";
// import { useDispatch } from "react-redux";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import {
  Grid,
  CardActionArea,
  Card,
  CardHeader,
  Avatar,
  CardActions,
} from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import LaunchIcon from "@mui/icons-material/Launch";
import Zoom from '@mui/material/Zoom';

// import { setCurrentPatientId } from "features/patients/patientsSlice";

const FriendItemComponent = (props) => {
  // const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // const handleClickTitle = () => {
  //   dispatch(setCurrentPatientId(props.model.id));
  // }

  // const handleLaunchApp = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   window.open(props.model.urlLink);
  // }

  return (<Zoom in={true} >
    <Grid sx={{
      transition: "all, 0.5",
      width: fullScreen ? "100%" : "unset",
    }}>
      <Card
        sx={{
          width: fullScreen ? "100%" : "350px",
          margin: "10px",
        }}
      >
        <CardActionArea
        // onClick={handleClickTitle}
        >
          <CardHeader
            titleTypographyProps={{ variant: "subtitle2" }}
            subheaderTypographyProps={{ variant: "subtitle1" }}
            sx={{ pb: "0px", alignItems: "flex-start" }}
            avatar={
              <Avatar
                src={props.model.avatar}
                sx={{ width: 62, height: 62 }}
              >
                <AccountCircleIcon fontSize="large" sx={{ fontSize: "72px" }} />
              </Avatar>
            }
            title={props.model.name}
            subheader={props.model.statusText}
          />
          <CardActions disableSpacing>
            {/* <Grid container>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <LaunchIcon title="Перейти в приложение" aria-label="Launch" onClick={handleLaunchApp} />
              </Grid>
            </Grid> */}
          </CardActions>
        </CardActionArea>
      </Card>
    </Grid>
  </Zoom>
  );
}


export const FriendItem = memo(FriendItemComponent);