import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetGetReferral } from "./referralAPI";

const initialState = {
  referral: {},
  loading: {
    referral: false,
  }
};

export const sendGetReferral = createAsyncThunk(
  "referral/fetchSendGetReferral",
  async (_, { getState }) => {
    const { auth } = getState();
    const response = await fetchGetGetReferral({ key: auth.key, authKey: auth.authKey });

    return response;
  }
);

export const authSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder

      .addCase(sendGetReferral.pending, (state) => {
        state.status = "loading";
        state.loading.referral = true;
      })

      .addCase(sendGetReferral.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.referral = false;

        let data = action.payload.data || {};

        const referral = data;

        state.referral = referral;
      })

      .addCase(sendGetReferral.rejected, (state, action) => {
        state.status = "idle";
        state.loading.referral = false;
      });
  },
});

export const selectReferral = (state) => state.referral.referral;
export const selectLoading = (state) => state.referral.loading;

export default authSlice.reducer;
