import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
    Grid,
} from '@mui/material';

import { Loader } from "UI";
import { selectLoading, sendGetPatientList, selectPatientList } from "features/patients/patientsSlice";
import { PatientItem } from "features/patients";

const PatientListComponent = () => {
    const dispatch = useDispatch();
    const { patientList: loading } = useSelector(selectLoading);

    const list = useSelector(selectPatientList);

    console.log(list);

    useEffect(() => {
        dispatch(sendGetPatientList());
    }, [dispatch]);

    return <Box sx={{ height: "calc(100vh - 142px)", width: '100%' }}>
        <Loader loading={loading} variant="replace">
            <Grid container sx={{
                display: "grid",
                gap: "20px",
                paddingTop: "10px",
                gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
            }}>
                {list.map((elem) => (
                    <PatientItem model={elem} key={elem.id} />
                ))}
            </Grid>
        </Loader>
    </Box>;
}

export const PatientList = memo(PatientListComponent);
