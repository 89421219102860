import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Alert,
    Box,
    Grid,
} from "@mui/material";

import { Loader } from "UI";

import {
    selectLoading,
    sendGetServiceList,
    selectServiceList,
    selectServiceApplicationId,
} from "features/pages/pagesSlice";

import { ServiceItem } from "features/pages"; // ServiceInfo

const ServiceListComponent = ({ id }) => {
    const dispatch = useDispatch();
    const { serviceList: loading } = useSelector(selectLoading);
    const serviceApplicationId = useSelector(selectServiceApplicationId);

    // console.log(serviceApplicationId);
    const list = useSelector(selectServiceList);

    // const listFiltered = list.filter(item => item.numberOfKeys);

    // console.log(list);

    useEffect(() => {
        if (serviceApplicationId && serviceApplicationId === id) {
            console.log(id);
            dispatch(sendGetServiceList({ id }));

        }
    }, [dispatch, serviceApplicationId, id]);

    if (serviceApplicationId !== id) {
        return "";
    }

    return <>
        {/* <ServiceInfo /> */}
        <Box sx={{ 
            height: "calc(100vh - 202px)",
             width: "100%",
             overflow: "auto"
              }}>
            <Loader loading={loading} variant="replace">
                <Grid container sx={{
                    display: "grid",
                    gap: "20px",
                    paddingTop: "10px",
                    gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
                }}>
                    {list.length ? list.map((elem) => (
                        <ServiceItem model={elem} key={elem.id} />
                    )) : <Alert severity="info" sx={{ m: 5 }}>Нет доступных услуг для данного сервиса</Alert>}
                </Grid>
            </Loader>
        </Box>
    </>;
}

export const ServiceList = memo(ServiceListComponent);
