import { API_URLS } from "constants";
import { POST } from "helpers";

export function fetchGetPatientList({ authKey }) {
  return POST({
    path: API_URLS.main.GetPatientList,
    data: {
      authKey,
    },
  });
}
