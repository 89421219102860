import React, { useEffect } from 'react';
import { AUTH_PATHS, CONFIG } from "../../constants";

import { Typography, Paper, Box, Tabs, Tab } from '@mui/material';
import { Routes, Route, Navigate, useLocation, Outlet, useNavigate, matchRoutes } from "react-router-dom"; // useNavigate

import { Alerts } from "features/alerts";
import { Login, Registration, Navigation, ResetPass, NewPass, Confirm, GetConfirm, LoginByKey } from 'features/auth';

import styles from './AuthForm.module.css';

function TabPanel(props) {
  const { value, index, children } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{ overflowY: "auto", overflowX: "hidden" }}
    >
      {value === index && (
        <Box sx={{ p: 1, pt: 3 }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

const isRegistrationTab = (location) => {
  const registrationPaths = AUTH_PATHS.filter(item => item.type === "registration");
  console.log(registrationPaths);

  const getClearLocation = (location) => {

    const pathArray = location.pathname.split("/");
    console.log(pathArray);

    return {
      ...location,
      pathname: `/${pathArray[1]}`,
    };
  }
  const isRegistration = matchRoutes(registrationPaths, getClearLocation(location));

  console.log(isRegistration);
  return isRegistration;
}

const getTitleByPath = (path) => {
  const pathItem = AUTH_PATHS.find(item => item.path === path);

  if (pathItem && pathItem.title) {
    return pathItem.title;
  }

  return "";
}

export function AuthForm() {
  const location = useLocation();
  const [tab, setTab] = React.useState(0);
  const navigate = useNavigate();

  const title = getTitleByPath(location.pathname);

  const handleChange = (event, newValue) => {
    setTab(newValue);

    if (newValue === 1) {
      navigate("/registration");
    } else {
      navigate("/login");
    }
  };


  useEffect(() => {
    console.log(location);
    if (isRegistrationTab(location)) {
      console.log('2');
      setTab(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.container}>
      <h1 data-text={CONFIG.title} className={styles.title}>{CONFIG.title}</h1>
      <h2 className={styles.title2}>{CONFIG.subtitle}</h2>

      <Paper className={styles.authBlock}>
        <Box className={styles.authContainer}>
          {CONFIG.formType === "full" && <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: "-22px -7px 0 -7px" }}>
            <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Авторизация" />
              <Tab label="Регистрация" />
            </Tabs>
          </Box>}

          <Typography variant='subtitle2' className={styles.subtitle}>{title}</Typography>
          <TabPanel value={tab} index={0}>
            <Routes>
              <Route path="/" element={<Outlet />}>
                <Route index element={<Navigate to="/login" />} />
                <Route path="*" element={CONFIG.formType === "keyOnly" ? <LoginByKey /> : <Login />} />
                <Route path="login" element={CONFIG.formType === "keyOnly" ? <LoginByKey /> : <Login />} />
                <Route path="resetpass" element={<ResetPass />} />

                <Route path="newpass" element={<NewPass />}>
                  <Route path=":code/:email/:service" element={<NewPass />} />
                </Route>

              </Route>
            </Routes>
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <Routes>
              <Route path="/" element={<Outlet />}>
                <Route path="registration" element={<Registration />} />
                <Route path="getconfirm" element={<GetConfirm />} />

                <Route path="confirm" element={<Confirm />}>
                  <Route path=":code/:email/:service" element={<Confirm />} />
                </Route>
              </Route>
            </Routes>
          </TabPanel>

          {CONFIG.formType === "full" && <Routes>
            <Route path="*" element={<Navigation tab={tab} />} />
          </Routes>}
        </Box>

      </Paper >
      <Alerts />
    </div>
  );
}
// return (
//   <div className={styles.container}>
//     <h1 data-text="Личный кабинет" className={styles.title}>Личный кабинет</h1>
//     <h2 className={styles.title2}>Socmedica</h2>

//     <Paper className={styles.authBlock}>
//       <Box className={styles.authContainer}>
//         <Typography variant='subtitle2'>{title}</Typography>
//         <Routes>
//           <Route index element={<Navigate to="/login" />} />
//           <Route path="*" element={<Login />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/registration" element={<Registration />} />
//           <Route path="/resetpass" element={<ResetPass />} />
//           <Route path="/newpass" element={<NewPass />} />
//         </Routes>
//       </Box>
//       <Routes>
//         <Route path="/login" element={<Navigation />} />
//         <Route path="/registration" element={<Navigation />} />
//         <Route path="/resetpass" element={<Navigation />} />
//         <Route path="/newpass" element={<Navigation />} />
//       </Routes>
//     </Paper >
//     <Alerts />
//   </div>
// );