import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetFriendList } from "./friendsAPI";

const initialState = {
  list: [],
  loading: {
    friendList: false,
  }
};

export const sendGetFriendList = createAsyncThunk(
  "friends/fetchSendGetFriendList",
  async (_, { getState }) => {
    const { auth } = getState();
    const response = await fetchGetFriendList({ key: auth.key, authKey: auth.authKey });

    return response;
  }
);

export const authSlice = createSlice({
  name: "friends",
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder

      .addCase(sendGetFriendList.pending, (state) => {
        state.status = "loading";
        state.loading.friendList = true;
      })

      .addCase(sendGetFriendList.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.friendList = false;

        let data = action.payload.data || {};

        const friendList = data.map(item => {
          return {
            ...item,
          };
        });

        state.list = friendList;
      })

      .addCase(sendGetFriendList.rejected, (state, action) => {
        state.status = "idle";
        state.loading.friendList = false;
      });
  },
});

export const selectFriendList = (state) => state.friends.list;
export const selectLoading = (state) => state.friends.loading;

export default authSlice.reducer;
