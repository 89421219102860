import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawerOpen: false,
  currentPage: "applicationList",
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const {
  setDrawerOpen,
  setCurrentPage,
} = mainSlice.actions;

export const selectDrawerOpen = (state) => state.main.drawerOpen;
export const selectCurrentPage = (state) => state.main.currentPage;

export default mainSlice.reducer;
