import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetPatientList } from "./patientsAPI";

const initialState = {
  list: [],
  loading: {
    patientList: false,
  }
};

export const sendGetPatientList = createAsyncThunk(
  "patients/fetchSendGetPatientList",
  async (_, { getState }) => {
    const { auth } = getState();
    const response = await fetchGetPatientList({ key: auth.key, authKey: auth.authKey });

    return response;
  }
);

export const authSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder

      .addCase(sendGetPatientList.pending, (state) => {
        state.status = "loading";
        state.loading.patientList = true;
      })

      .addCase(sendGetPatientList.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.patientList = false;

        let data = action.payload.data || {};

        const patientList = data.map(item => {
          return {
            ...item,
          };
        });

        state.list = patientList;
      })

      .addCase(sendGetPatientList.rejected, (state, action) => {
        state.status = "idle";
        state.loading.patientList = false;
      });
  },
});

export const selectPatientList = (state) => state.patients.list;
export const selectLoading = (state) => state.patients.loading;

export default authSlice.reducer;
