import { API_URLS } from "constants";
import { POST } from "helpers";

export function fetchGetFriendList({ authKey }) {
  return POST({
    path: API_URLS.main.GetFriendList,
    data: {
      authKey,
    },
  });
}
