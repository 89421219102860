import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetGetWallet } from "./walletAPI";

const initialState = {
  wallet: {},
  loading: {
    wallet: false,
  }
};

export const sendGetWallet = createAsyncThunk(
  "wallet/fetchSendGetWallet",
  async (_, { getState }) => {
    const { auth } = getState();
    const response = await fetchGetGetWallet({ key: auth.key, authKey: auth.authKey });

    return response;
  }
);

export const authSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder

      .addCase(sendGetWallet.pending, (state) => {
        state.status = "loading";
        state.loading.wallet = true;
      })

      .addCase(sendGetWallet.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.wallet = false;

        let data = action.payload.data || {};

        const wallet = data;

        state.wallet = wallet;
      })

      .addCase(sendGetWallet.rejected, (state, action) => {
        state.status = "idle";
        state.loading.wallet = false;
      });
  },
});

export const selectWallet = (state) => state.wallet.wallet;
export const selectLoading = (state) => state.wallet.loading;

export default authSlice.reducer;
