import React, { memo } from "react";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Zoom,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import WorkIcon from "@mui/icons-material/Work";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const ServiceItemComponent = (props) => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let options = props.model.serviceOptions || [];

  return (<Zoom in={true} >
    <Grid sx={{
      transition: "all, 0.5",
      width: mediumScreen ? "97%" : "unset",
    }}>
      <Card
        sx={{
          width: mediumScreen ? "97%" : "350px",
          margin: "10px",
        }}
      >
        <CardHeader
          titleTypographyProps={{ variant: "subtitle2" }}
          subheaderTypographyProps={{ variant: "subtitle1" }}
          sx={{ pb: "0px", alignItems: "flex-start" }}

          avatar={
            <Avatar
              src={props.model.avatar}
              sx={!smallScreen ? { width: 62, height: 62 } : {}}
            >
              <WorkIcon fontSize="large" sx={{ fontSize: smallScreen ? "32px" : "42px" }} />
            </Avatar>
          }
          title={props.model.name}
          subheader={`Цена: ${props.model.price}`}
        />
        {/* <CardActions disableSpacing>
          <Grid container>
            <Grid
              item
              xs={8}
            >
              <IconButton color="info" aria-label="keys" title="Ключи" onClick={handleClickTitle}>
                <KeyIcon />
              </IconButton>
              <IconButton color="info" aria-label="service" title="Услуги" onClick={handleClickService}>
                <WorkIcon />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton title="Перейти в приложение" aria-label="Launch" onClick={handleLaunchApp}>
                <LaunchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </CardActions> */}
        {options.length && <CardContent>
          <List dense>
            {options.map(option =>
              <ListItem key={option.id}>
                <ListItemIcon
                  sx={{ minWidth: "30px" }}>
                  <TaskAltIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary={option.name}
                />
              </ListItem>
            )} </List>
        </CardContent>}

        <CardActions sx={{ justifyContent: "end" }}>
          <Button variant="outlined">Купить</Button>
        </CardActions>
      </Card>
    </Grid>
  </Zoom>
  );
}


export const ServiceItem = memo(ServiceItemComponent);