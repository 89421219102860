import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DRAWER_WIDTH } from "constants";

import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    Drawer,
    CssBaseline,
    Divider,
    IconButton,
} from "@mui/material";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Bar } from "features/main";
import { Content } from "features/content";
import { Alerts } from "features/alerts";

import { selectDrawerOpen, setDrawerOpen } from "features/main/mainSlice";

const drawerWidth = DRAWER_WIDTH;

const ContentHolder = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export const Main = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const open = useSelector(selectDrawerOpen);

    // const handleDrawerOpen = () => dispatch(setDrawerOpen(true));
    const handleDrawerClose = () => dispatch(setDrawerOpen(false));

    return (
        <Box sx={{ display: 'flex', overflow: "auto" }}>
            <CssBaseline />
            <Bar />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                {/* <Filters /> */}

            </Drawer>
            <ContentHolder open={open}>
                <DrawerHeader />
                <Content />
            </ContentHolder>
            <Alerts />
        </Box>
    );
}
