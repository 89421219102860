import React, { memo } from "react";

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    VKShareButton,
    OKShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,

    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    VKIcon,
    OKIcon,
    TelegramIcon,
    WhatsappIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
} from "react-share";

import {
    Box,
    Tooltip,
} from '@mui/material';

const components = {
    Facebook: [FacebookShareButton, FacebookIcon],
    Twitter: [TwitterShareButton, TwitterIcon],
    Linkedin: [LinkedinShareButton, LinkedinIcon],
    VK: [VKShareButton, VKIcon],
    OK: [OKShareButton, OKIcon],
    Telegram: [TelegramShareButton, TelegramIcon],
    Whatsapp: [WhatsappShareButton, WhatsappIcon],
    Mailru: [MailruShareButton, MailruIcon],
    Email: [EmailShareButton, EmailIcon],
    Livejournal: [LivejournalShareButton, LivejournalIcon],
    Viber: [ViberShareButton, ViberIcon],
};



const ShareComponent = ({ link, title }) => {

    const ComplexShareIcon = (props) => {
        // const { ButtonComponent, IconComponent } = components[componentName];
        const { componentName, ...buttonProps } = { ...props }
        const ButtonComponent = components[componentName][0];
        const IconComponent = components[componentName][1];

        return <Tooltip title={componentName}>
            <span>
                <ButtonComponent
                    {...buttonProps}
                    url={link}
                >
                    <IconComponent size={42} round />
                </ButtonComponent>
            </span>
        </Tooltip>;
    }

    return <Box sx={{
        height: "342px",
        width: "342px",
        "& button": {
            margin: "8px"
        }
    }}>

        <ComplexShareIcon
            componentName={"Facebook"}
            quote={title}
        />

        <ComplexShareIcon
            componentName={"Twitter"}
            title={title}
        />

        <ComplexShareIcon
            componentName={"Linkedin"}
        />

        <ComplexShareIcon
            componentName={"VK"}
        // image={`${String(window.location)}/${exampleImage}`}
        />

        <ComplexShareIcon
            componentName={"OK"}
        // image={`${String(window.location)}/${exampleImage}`}
        />

        <ComplexShareIcon
            componentName={"Telegram"}
            title={title}
        />

        <ComplexShareIcon
            componentName={"Whatsapp"}
            title={title}
            separator=":: "
        />

        <ComplexShareIcon
            componentName={"Mailru"}
            title={title}
        />

        <ComplexShareIcon
            componentName={"Email"}
            subject={title}
            body={link}
        />

        <ComplexShareIcon
            componentName={"Livejournal"}
            title={title}
            description={link}
        />

        <ComplexShareIcon
            componentName={"Viber"}
            title={title}
        />
        {/* <Divider /> */}

        <Box sx={{
            border: "1px solid lightgray",
            borderRadius: "4px",
            p: 2,
            mt: 2
        }}
        >{link}</Box>
    </Box >;
}

export const Share = memo(ShareComponent);
