import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
    Grid,
    Alert,
} from '@mui/material';

import { Loader } from "UI";
import { selectLoading, sendGetFriendList, selectFriendList } from "features/friends/friendsSlice";
import { FriendItem } from "features/friends";

const FriendListComponent = () => {
    const dispatch = useDispatch();
    const { friendList: loading } = useSelector(selectLoading);

    const list = useSelector(selectFriendList);

    console.log(list);

    useEffect(() => {
        dispatch(sendGetFriendList());
    }, [dispatch]);




    return <Box sx={{ height: "calc(100vh - 142px)", width: '100%' }}>
        <Loader loading={loading} variant="replace">
            <Grid container sx={{
                display: "grid",
                gap: "20px",
                paddingTop: "10px",
                gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
            }}>
                {list.length ? list.map((elem) => (
                    <FriendItem model={elem} key={elem.id} />
                )) : <Alert severity="info">В вашем списке коллег пока пусто</Alert>}
            </Grid>
        </Loader>
    </Box>;
}

export const FriendList = memo(FriendListComponent);
