import { API_URLS } from "constants";
import { POST } from "helpers";

export function fetchGetGetReferral({ authKey }) {
  return POST({
    path: API_URLS.main.GetReferral,
    data: {
      authKey,
      applicationNumber: 303,
      agentEmail: "",
      agentId: 0
    },
  });
}
