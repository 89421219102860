import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Box, Alert, Typography } from '@mui/material';

import { TXT } from "constants";
import { Loader } from "UI";

import { ApplicationList } from "features/pages";
import { PatientList } from "features/patients";
import { FriendList } from "features/friends";
import { Referral } from "features/referral";
import { Wallet } from "features/wallet";
import { selectCurrentPage } from "features/main/mainSlice";

const ContentMemedComponent = () => {
    const currentPage = useSelector(selectCurrentPage);

    let ContentComponent = null;
    let title = "";

    switch (currentPage) {

        case "applicationList":
            ContentComponent = ApplicationList;
            title = TXT.applicationList;
            break;

        case "wallet":
            ContentComponent = Wallet;
            title = TXT.wallet;
            break;

        case "patientList":
            ContentComponent = PatientList;
            title = TXT.patientList;
            break;

        case "friendList":
            ContentComponent = FriendList;
            title = TXT.friendList;
            break;

        case "referral":
            ContentComponent = Referral;
            title = TXT.inviteColleague;
            break;

        default:
            ContentComponent = <Loader loading={true} ><Alert severity="info">Загрузка [unknown module].</Alert></Loader>;
            break;
    }

    return <Box sx={{ height: "calc(100vh - 82px)", width: '100%' }}>
        <>
            <Typography variant="h6" sx={{ ml: "1%" }}>{title}</Typography>
            <ContentComponent />
        </>
    </Box>;
}

export const Content = memo(ContentMemedComponent);
