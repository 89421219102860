import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// import { QRCodeSVG } from 'qrcode.react';

import {
    Box,
    Grid,
    Alert,
    Typography,
    Card,
    CardHeader,
} from '@mui/material';

import { Loader } from "UI";
import { 
    selectLoading,
    //  sendGetReferral, 
    //  selectReferral,
     } from "features/wallet/walletSlice";

// import { Share } from "../Share/Share";


const WalletComponent = () => {
    const dispatch = useDispatch();
    const { wallet: loading } = useSelector(selectLoading);

    // const referral = useSelector(selectReferral);

    // console.log(referral);


    // const referralLink = referral.link;

    // useEffect(() => {
    //     dispatch(sendGetReferral());
    // }, [dispatch]);

    // if (!referralLink && !loading) {
    //     return <Alert severity="error">Не удалось сгенерировать реферальную ссылку</Alert>;
    // }

    // const referralTitle = "Личный кабинет";


    return <Box sx={{ height: "calc(100vh - 142px)", width: '100%' }}>
        <Grid item xs={12} sx={{ margin: 3 }}>
            <Alert severity="info">
                <Typography>Вы можете поделиться приложением одним из следующих способов:</Typography>
                <Box
                    component={"ul"}
                    sx={{
                        listStyleType: "decimal",
                        p: 4,
                        pt: 0,
                    }}>
                    <li>Покажите вашему коллеге QR-код. Коллега получит ссылку отсканировав код своим мобильным устройством</li>
                    <li>Кликните на иконку одного из социальных сервисов и укажите в качестве получателя вашего коллегу</li>
                    <li>Скопируйте и отправьте ссылку вашему коллеге любым способом </li>
                </Box>
            </Alert>
        </Grid>

        <Loader loading={loading} variant="replace">
            <Box sx={{
                display: "flex",
            }}>

                <Card
                    sx={{
                        width: "100%",
                        margin: "10px",
                        padding: "20px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <CardHeader
                        titleTypographyProps={{ variant: "subtitle2" }}
                        sx={{ pb: "0px", alignItems: "flex-start" }}
                        title={"QR-код"}
                    />
                    {/* <QRCodeSVG value={referralLink} size="340" /> */}
                </Card>

                <Card
                    sx={{
                        width: "100%",
                        margin: "10px",
                        padding: "20px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <CardHeader
                        titleTypographyProps={{ variant: "subtitle2" }}
                        sx={{ pb: "0px", alignItems: "flex-start" }}
                        title={"Поделиться ссылкой"}
                    />
                    {/* <Share link={referralLink} title={referralTitle} /> */}
                </Card>
            </Box>
        </Loader>
    </Box>;
}

export const Wallet = memo(WalletComponent);
