import { SERVER } from './server';

const SERVERS = {
    prod: {
        api: "https://service.socmedica.com:9101",
        auth: "https://service.socmedica.com:9101",
    },
    dev: {
        api: "https://service.socmedica.dev:9101",
        auth: "https://service.socmedica.dev:9101",
    },
    box: {
        api: "http://ecp.box:30946",
        auth: "http://ecp.box:44380",
    },
};


function getUrls() {
    const { api: APIServer } = SERVERS[SERVER];

    return {
        root: APIServer,
        main: {
            GetApplicationList: APIServer + "/Api/Subscription/GetApplicationList",
            GetServiceList: APIServer + "/Api/Subscription/GetCurrentServiceOptions",
            GetApplicationInfo: APIServer + "/Api/Subscription/GetCurrentApplication",
            GetPatientList: APIServer + "/Api/UserInformation/GetMyPatients",
            GetFriendList: APIServer + "/Api/UserInformation/GetMyColleagues",
            GetReferral: APIServer + "/Api/Referral/GetReferralLink",
            // GetUserInfo: APIServer + "/Pages/GetPages",
            // Search: APIServer + "/Search/ConceptList",
        },
    }
}

export const API_URLS = getUrls();