import { API_URLS } from "constants";
import { POST } from "helpers";

export function fetchGetApplicationList({ authKey }) {
  return POST({
    path: API_URLS.main.GetApplicationList,
    data: {
      authKey,
    },
  });
}

export function fetchGetApplicationInfo({ authKey, id }) {
  return POST({
    path: API_URLS.main.GetApplicationInfo,
    data: {
      id: +id,
      authKey,
    },
  });
}

export function fetchGetServiceList({ authKey, id }) {
  return POST({
    path: API_URLS.main.GetServiceList,
    data: {
      id: +id,
      authKey,
    },
  });
}

export function fetchGetServiceInfo({ authKey, id }) {
  return POST({
    path: API_URLS.main.GetServiceInfo,
    data: {
      id: +id,
      authKey,
    },
  });
}
